<template>
  <!-- begin::InvoiceCenters edit -->
  <div>
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/facturacion-primary.svg"
      />
      <div>
        {{
          InvoiceCenterID > 0
            ? $t("INVOICE_CENTERS.TITLE_EDIT")
            : $t("INVOICE_CENTERS.TITLE_ADD_2")
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("INVOICE_CENTERS.SUBTITLE") }}
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("INVOICE_CENTERS.NICK") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Nick"
              :placeholder="$t('INVOICE_CENTERS.NICK')"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("INVOICE_CENTERS.FISCAL_NAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.FiscalName"
              :placeholder="$t('INVOICE_CENTERS.FISCAL_NAME')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("INVOICE_CENTERS.FISCAL_ID") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.FiscalID"
              :placeholder="$t('INVOICE_CENTERS.FISCAL_ID')"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("INVOICE_CENTERS.FULL_ADDRESS") }}
            </label>
            <VueGoogleAutocomplete
              ref="autocompleteAddress"
              id="autocompleteAddress"
              country="es"
              :fields="['address_components', 'geometry', 'id', 'name']"
              v-model="Fields.Street"
              :rules="[rules.required]"
              required
              classname="form-control"
              placeholder=""
              @placechanged="onAutocompletePlaceChanged"
              @inputChange="onAutocompleteInputChange"
            />
            <div
              v-if="!addressValid"
              class="v-text-field__details pt-2 pl-5 pb-0 m-0"
            >
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ $t("VALIDATION.REQUIRED_FIELD") }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-lg-2">
            <label>
              {{ $t("INVOICE_CENTERS.POSTAL_CODE_2") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.PostalCode"
              :placeholder="$t('COMPANIES.POSTAL_CODE')"
              required
              :rules="[rules.required]"
              @keyup="onPostalCodeChanged"
            />
          </div>

          <div class="form-group col-lg-3">
            <label>
              {{ $t("INVOICE_CENTERS.CITY") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.City"
              :placeholder="$t('COMPANIES.CITY')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-3">
            <label>
              {{ $t("INVOICE_CENTERS.PROVINCE") }}
            </label>
            <select
              class="form-control select-down"
              v-model="Fields.ProvinceID"
              required
            >
              <option
                v-for="p in ProvinceOptions"
                :key="p.ProvinceID"
                :value="p.ProvinceID"
                class="text-primary"
              >
                {{ p.Name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("INVOICE_CENTERS.EMAIL_MANAGER") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.EmailManager"
              :placeholder="$t('INVOICE_CENTERS.EMAIL_MANAGER')"
              required
              :rules="[rules.requiredEmail]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("INVOICE_CENTERS.EMAIL_ACCOUNTING") }}
              <span class="font-weight-normal">
                ({{ $t("GENERIC.OPTIONAL") }})
              </span>
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.EmailAccounting"
              :placeholder="$t('INVOICE_CENTERS.EMAIL_ACCOUNTING')"
              :rules="[rules.optionalEmail]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>
        </div>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/invoicecenters')"
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid || !addressValid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::InvoiceCenters edit -->
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import VueGoogleAutocomplete from "vue-google-autocomplete";

import InvoiceCenterService from "@/core/services/api/v2/invoicecenter.service";
import ProvinceService from "@/core/services/api/v2/province.service";

export default {
  name: "InvoiceCentersEdit",
  components: {
    VueGoogleAutocomplete
  },
  computed: {
    addressValid() {
      return this.Fields.Street.length > 0;
    }
  },
  data() {
    return {
      InvoiceCenterID: 0,
      ProvinceOptions: [],
      Valid: true,
      Fields: {
        Nick: "",
        FiscalName: "",
        FiscalID: "",
        Street: "",
        PostalCode: "",
        City: "",
        ProvinceID: 0,
        EmailManager: "",
        EmailAccounting: ""
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        requiredEmail: value => {
          if (value.length > 0 && /.+@.+\..+/.test(value)) {
            return true;
          } else {
            return this.$i18n.t("VALIDATION.INVALID_EMAIL");
          }
        },
        optionalEmail: value => {
          if (!value || value.length === 0 || /.+@.+\..+/.test(value)) {
            return true;
          } else {
            return this.$i18n.t("VALIDATION.INVALID_EMAIL");
          }
        }
      }
    };
  },
  created() {
    this.loadProvinceOptions();
  },
  mounted() {
    // Edit (a certain "id") or create new?
    if (this.$route.params.id) {
      this.InvoiceCenterID = parseInt(this.$route.params.id);
      this.loadCenterData();
    } else {
      this.InvoiceCenterID = -1;
    }
  },
  methods: {
    loadProvinceOptions() {
      this.ProvinceOptions = [];
      ProvinceService.listAll().then(response => {
        this.ProvinceOptions = response;
      });
    },

    loadCenterData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      InvoiceCenterService.getProfile(this.InvoiceCenterID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/invoicecenters");
        } else {
          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    onAutocompletePlaceChanged(addressData) {
      this.Fields.Street =
        addressData.route +
        (addressData.street_number ? " " + addressData.street_number : "");

      this.Fields.PostalCode = addressData.postal_code;
      this.Fields.City = addressData.locality;
      this.onPostalCodeChanged();

      // Clean extra values from Street box
      this.$refs.autocompleteAddress.$refs.autocomplete.value = this.Fields.Street;
    },

    onAutocompleteInputChange(e) {
      if (e.newVal.trim().length === 0) {
        this.Fields.Street = "";
      }
    },

    onPostalCodeChanged() {
      // Convert PostalCode to an item in our ProvinceID selector
      // N.B: Province is CRITICAL because it sets the fare,
      // but Google doesn't give it,
      // that's why we have to guess it via PostalCode...
      // and why we restrict VueGoogleAutocomplete component to country="es"
      let newPostalCode = parseInt(this.Fields.PostalCode);
      let newProvinceID = 1;

      for (let p = 0; p < this.ProvinceOptions.length; p++) {
        let maybeProvince = this.ProvinceOptions[p];
        let pcStart = parseInt(maybeProvince.PostalCodeStart);
        let pcEnd = parseInt(maybeProvince.PostalCodeEnd);
        if (newPostalCode >= pcStart && newPostalCode <= pcEnd) {
          newProvinceID = maybeProvince.ProvinceID;
          break;
        }
      }

      this.Fields.ProvinceID = newProvinceID;
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        if (this.InvoiceCenterID > 0) {
          // Update InvoiceCenter
          InvoiceCenterService.updateProfile(
            this.InvoiceCenterID,
            this.Fields
          ).then(statusCode => {
            if (statusCode >= 400) {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (statusCode === 409) {
                whyError = this.$i18n.t("INVOICE_CENTERS.ERROR_409");
              } else if (statusCode === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        } else {
          // Create new InvoiceCenter: we get back its ID
          InvoiceCenterService.createProfile(this.Fields).then(response => {
            if (response.InvoiceCenterID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.InvoiceCenterID = response.InvoiceCenterID;
              setTimeout(
                () =>
                  this.$router.push(
                    "/manager/invoicecenters/edit/" + this.InvoiceCenterID
                  ),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 409) {
                whyError = this.$i18n.t("INVOICE_CENTERS.ERROR_409");
              } else if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    }
  }
};
</script>

import ApiService from "@/core/services/api/v2/api.service";

const INVOICE_CENTER_LIST_KEY = "InvoiceCenterList";
const INVOICE_CENTER_FILTER_KEY = "InvoiceCenterFilter";

const InvoiceCenterService = {
  getProfile(invoiceCenterID) {
    return new Promise(resolve => {
      ApiService.get("invoiceCenter/" + invoiceCenterID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateProfile(invoiceCenterID, newData) {
    return new Promise(resolve => {
      ApiService.put("invoiceCenter/" + invoiceCenterID, newData)
        .then(response => {
          this.cleanCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteProfile(invoiceCenterID) {
    return new Promise(resolve => {
      ApiService.delete("invoiceCenter/" + invoiceCenterID)
        .then(response => {
          this.cleanCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createProfile(newData) {
    return new Promise(resolve => {
      ApiService.post("invoiceCenter", newData)
        .then(response => {
          this.cleanCachedList();
          // Response (if correct) holds the new InvoiceCenterID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  search(searchText, filterByProvinceID, orderBy, orderDesc, limit, offset) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByProvinceID = parseInt(filterByProvinceID);
      if (!isNaN(filterByProvinceID) && filterByProvinceID > 0) {
        queryParams.ProvinceID = filterByProvinceID;
      }

      window.sessionStorage.setItem(
        INVOICE_CENTER_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("invoiceCenter", { params: queryParams })
        .then(response => {
          // Return full response, not just "InvoiceCenters" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFilters() {
    let cachedFilters = window.sessionStorage.getItem(
      INVOICE_CENTER_FILTER_KEY
    );
    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  },

  listAll() {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(INVOICE_CENTER_LIST_KEY);
      if (cachedList) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.query("invoiceCenter", { params: { Limit: 999 } })
          .then(response => {
            let newList = response.data.response.InvoiceCenters;

            window.sessionStorage.setItem(
              INVOICE_CENTER_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  cleanCachedList() {
    window.sessionStorage.removeItem(INVOICE_CENTER_LIST_KEY);
  }
};

export default InvoiceCenterService;
